import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import styled from "@emotion/styled"
import hara from "../images/hara-kiri.png"
import mobile from "../images/ramen-truffe-mobile2.jpg"
import arrow from "../images/down.svg"
import Carousel from "./Carousel"
import CTA from "./CTA"
import CTAmobile from "./CTAmobile"
import { useMediaQuery } from "react-responsive"

const Entrance = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 640px)" })
  return (
    // <Outer>
    <StyledSection
      id="home"
      style={{ position: "relative", scrollSnapAlign: "start" }}
    >
      <MainActionContainer>
        <StyledImage src={hara} alt="Hara-kiri logo"></StyledImage>
        {isMobile ? <CTAmobile></CTAmobile> : <CTA />}
      </MainActionContainer>

      <SliderContainer>
        {isMobile ? <Truffe src={mobile}></Truffe> : <Carousel />}
      </SliderContainer>
      <ArrowLink onClick={() => scrollTo("#quote")} className="arrowLink">
        <Arrow src={arrow} alt={"scroll to restaurant section"}></Arrow>
      </ArrowLink>
    </StyledSection>
    // </Outer>
  )
}

const StyledSection = styled.section`
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 20;
  background-color: #fff;

  /* @media screen and (max-width: 640px) {
    height: 60vh;
  } */

  ::after {
    z-index: 20;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const Truffe = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ArrowLink = styled.button`
  z-index: 30;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
`

const MainActionContainer = styled.div`
  width: 25%;
  min-width: 320px;
  max-width: 400px;
  z-index: 100;
  position: absolute;
  left: 12%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 640px) {
    width: 90vw;
    max-width: auto;
    left: 50%;
    top: 29%;
    transform: translate(-50%, -50%);
  }
`

const StyledImage = styled.img`
  width: 100%;
  margin-bottom: 40px;

  @media screen and (max-width: 640px) {
    width: 80%;
    margin: 0 auto 40px;
  }
`

const Arrow = styled.img`
  transition: all 0.2s;

  :hover {
    transform: scale(1.2);
  }
`

export default Entrance
