import React, { useEffect } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.css"
import "./css/overrides.css"

import sakesEntrance from "../images/sakesZoom.jpg"
import hkt1 from "../images/hkt1.jpg"
import hkt2 from "../images/hkt2.jpg"

export default () => {
  useEffect(() => {
    const dots = document.querySelectorAll(".dot")
    dots.forEach(dot => dot.setAttribute("role", "none"))
    console.log("mount it!")
  }, [])

  return (
    <Carousel
      autoPlay
      showThumbs={false}
      infiniteLoop={true}
      showStatus={false}
      showArrows={false}
      // dynamicHeight={true}
      interval={4000}
      transitionTime={1000}
    >
      <div>
        <img src={hkt1} alt="Hara Kiri Ramen Truffe" />
      </div>
      <div>
        <img src={hkt2} alt="Ramen spicy hara kiri paris" loading="lazy" />
      </div>
      <div>
        <img
          src={sakesEntrance}
          alt="Mur de bouteilleS de saké hara kiri ramen paris"
          loading="lazy"
        />
      </div>
    </Carousel>
  )
}
