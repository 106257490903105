import React from "react"
import { Link } from "gatsby"
import "./css/ctaStyles.css"

const CTAmobile = props => {
  return (
    <Link to="/plats">
      <div
        className="example_f"
        href="add-website-here"
        target="_blank"
        rel="nofollow"
      >
        <span>Découvrez nos ramens</span>
      </div>
    </Link>
  )
}

export default CTAmobile
