import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Entrance from "../components/Entrance"
import Quote from "../components/Quote"
import Info from "../components/Info"

const SnapContainer = styled.div`
  overflow: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;

  @media screen and (max-width: 640px) {
    scroll-snap-type: none;
    scroll-behavior: none;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home | Hara Kiri Ramen"
      description="Installé au cœur du 10ème arrondissement, Hara Kiri est une cantine japonaise spécialisée en ramen."
    />
    <SnapContainer>
      <Entrance />
      <Quote />
      <Info />
    </SnapContainer>
  </Layout>
)

export default IndexPage
