import React from "react"
import styled from "@emotion/styled"
import red from "../images/red.jpg"
import { Link } from "gatsby"
import arrow from "../images/down.svg"
import "./css/ctaStyles2.css"
import scrollTo from "gatsby-plugin-smoothscroll"

const Quote = () => {
  return (
    <RestContainer
      id="quote"
      style={{ position: "relative", scrollSnapAlign: "start" }}
    >
      <ImageContainer>
        <img
          src={red}
          alt="Salle du restaurant de ramens Hara Kiri paris"
          loading="lazy"
        ></img>
      </ImageContainer>
      <RightContainer className="starters">
        <Text>
          <p className="title">LE RESTAURANT</p>
          {/* <p className="subtitle">WORDING 10 LIGNES ---- TITRE</p> */}
          <p className="text">
            Installé au cœur du 10ème arrondissement, Hara Kiri est une cantine
            japonaise spécialisée en ramen. Pour vous faire découvrir la
            richesse de cette soupe réconfortante et typique de la culture
            japonaise, le chef a imaginé une carte à la fois authentique et
            raffinée.
          </p>
          <p className="text ">
            Nous proposons 5 entrées pour réveillez vos papilles par des saveurs
            nippones traditionnelles comme des gyozas ou un tataki de bœuf
            mariné. Ensuite, notre carte se compose de 4 recettes emblématiques
            de ramens, auxquelles nous avons ajouté une recette signature : la
            ramen à la truffe noire. Miso, Shoyu ou encore Shoyu Yuzu : tous nos
            bouillons sont d’origine végétale et agrémentés de nouilles, de
            pousses de bambou, de cébette, d’algue nori et de la viande de votre
            choix (porc ou poulet).
          </p>
          <p className="text ">
            Dans l’esprit d’un Isakaya, notre cantine offre un large choix de
            Saké, directement importés du Japon. Secs, fruités ou pétillants,
            ils se marient parfaitement avec les saveurs de nos différents
            ramens. Vous pourrez également déguster notre bière artisanale bio
            Hara-Kiri, brassée à Paris.
          </p>
          <p className="text text-last">
            Chez Hara-Kiri, nous revendiquons un savoir-faire traditionnel
            japonais auquel nous avons apporté notre touche de créativité pour
            vous faire vivre une expérience culinaire inoubliable, le tout dans
            une ambiance chaleureuse et décontractée.
          </p>
        </Text>
        <Link to={"/restaurant"}>
          <div
            className="example_f2"
            href="add-website-here"
            target="_blank"
            rel="nofollow"
          >
            <span>Notre Histoire</span>
          </div>
        </Link>
        <ArrowLink onClick={() => scrollTo("#infos")} className="arrowLink">
          <Arrow src={arrow} alt="Scroll to Info section"></Arrow>
        </ArrowLink>
      </RightContainer>
    </RestContainer>
  )
}

const RestContainer = styled.section`
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 30;
  display: flex;

  @media screen and (max-width: 640px) {
    height: auto;
    /* margin-top: 100px; */
    flex-wrap: wrap;
  }
`

const ImageContainer = styled.div`
  width: 40%;
  overflow: hidden;

  @media screen and (max-width: 640px) {
    height: 50vh;
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const RightContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  justify-content: center;

  @media screen and (max-width: 640px) {
    /* height: 50vh; */
    width: 100%;
    padding: 20% 10% 10% 10%;
  }
`

const Text = styled.div`
  width: 80%;
  margin: 0 auto;
  /* height: 74vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 640px) {
    width: 100%;
    height: auto;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
  }

  .text {
    text-align: justify;
    line-height: 1.6;
    font-size: 15px;
    margin-bottom: 16px;
  }

  .text-last {
    margin-bottom: 40px;
  }
`

const ArrowLink = styled.button`
  /* z-index: 30;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px; */
  margin-top: 80px;
  object-fit: none;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;

  @media screen and (max-width: 640px) {
    margin-top: 40px;
  }
`

const Arrow = styled.img`
  transition: all 0.2s;

  :hover {
    transform: scale(1.2);
  }
`

export default Quote
