import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import "./css/ctaStyles.css"

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

const CTA = props => {
  const [count, setCount] = useState(0)
  const [action, setAction] = useState("nos ramens")
  const [link, setLink] = useState("/plats")

  useInterval(() => {
    // Your custom logic here
    setCount(count + 1)
    if (count === 0) {
      setAction("notre cantine")
      setLink("/restaurant")
    }
    if (count === 1) {
      setAction("nos sakés")
      setLink("/sakes")
    }
    if (count === 2) {
      setCount(0)
      setAction("nos ramens")
      setLink("/plats")
    }
  }, 4000)

  return (
    <Link to={link}>
      <div
        className="example_f"
        href="add-website-here"
        target="_blank"
        rel="nofollow"
      >
        <span>Découvrez {action}</span>
      </div>
    </Link>
  )
}

export default CTA
